<template>
  <transition name="modal">
    <div class="modal-mask" style="overflow-y: scroll;">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="send-custom-offer-main">

          <p>Sipariş ile ilgili herhangi bir sorunda, size hızlı bir şekilde ulaşabilmemiz için lütfen iletişim bilgilerini gir.
            Buradaki bilgiler <span style="color: #2d3640">kesinlikle</span> karşı tarafla paylaşılmamaktadır.</p>
          <div>
            <label class="custom-offer-label" style="margin-top: 34px;">İsim</label>
            <input v-model="name" style="width: 455px; height: 30px;" />

            <label class="custom-offer-label">Soyisim</label>
            <input v-model="surname" style="width: 455px; height: 30px;" />

            <label class="custom-offer-label">Cep Telefonu</label>
            <input :readonly="gsm_is_activated" maxlength="10" v-model="phoneNumber" type="number" style="width: 455px; height: 30px;" />
            <p v-if="!gsm_is_activated" style="font-size: 12px; margin-top: 5px; line-height: 15px;">Başında sıfır olmadan 10 hane olacak şekilde telefon numaranı gir. <br>Örn. 5326667788</p>

          </div>
          <div class="seperator"></div>
          <button :disabled="disableToSend" @click="saveContactInfo()" class="super-button" style="">Devam</button>
        </div>
      </div>
      </div>
  </transition>


</template>

<script>

  export default {
    name: "src-pages-modals-orderContactInfo-v1",
    data () {
      return {
        name: null,
        surname: null,
        phoneNumber: null,
        disableSendButton: false,
        gsm_is_activated: false,

      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      saveContactInfo() {
        this.disableSendButton =  true;
        this.api.orders.saveContactInfo(this.name, this.surname, this.phoneNumber)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              document.getElementsByClassName("bodyContainer")[0].style.pointerEvents = "";
              this.$store.commit(this.types.DISABLE_MODAL);
              this.disableSendButton =  false;
              this.user.firstname = this.name;
              this.user.lastname = this.surname;
              this.user.info.gsm = this.phoneNumber;

            } else {
              this.disableSendButton =  false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.disableSendButton =  false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },

    computed: {
      getModalInfo() {
        document.getElementsByClassName("bodyContainer")[0].style.pointerEvents = "none";
        this.name = this.user.firstname;
        this.surname = this.user.lastname;
        this.phoneNumber = this.user.info.gsm;
        this.gsm_is_activated = this.user.info.gsm_is_activated;
        return true;
      },

      disableToSend() {
        return !this.name || !this.surname || !this.phoneNumber || this.disableSendButton;
      }
    }


  }

</script>

<style scoped lang="scss">
  .modal-container {
    margin-top: 20px;
    padding: 0;
    font-family: 'sofia-pro';
    overflow-y: scroll;
    width: 100vw !important;
    max-width: 690px !important;
    height: 100%;
    max-height: 600px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    position: relative;
  }

  .send-custom-offer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    padding: 50px 100px 50px 100px;
  }

  .custom-offer-header {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .custom-offer-label {
    font-size: 14px;
    text-align: left;
    color: #2d3640;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .price-duration {
    display: flex;
  }

  .seperator {
    width: 100%;
    height: 2px;
    border-bottom: solid 1px #dfe4ec;
    margin-top: 30px;
    margin-bottom: 18px;
  }
  .super-button {
    min-width: 170px;
    height: 50px;
    border-radius: 2px;
    background-color: #FD4056;
    &:hover {
      background-color: #e94258;
    }
  }
</style>
